
import { defineComponent, ref } from "vue"
import { Modal } from "bootstrap"
import EditTeamMember from "@/components/modals/EditTeamMemberModal.vue"
import ApiService from "@/core/services/ApiService"
import Swal from "sweetalert2/dist/sweetalert2.js"
import _ from "lodash"
import { FilterMatchMode, FilterOperator } from "primevue/api"

export default defineComponent({
  name: "TeamMembersTable",
  components: {
    EditTeamMember,
  },
  props: {
    forceReload: Boolean
  },
  data() {
    return {
      teamMembersFilters: {},
      loading: ref(true),
      teamMembers: [] as Record<string, any>[]
    }
  },
  async created() {
    this.initTeamMembersFilters()
    this.teamMembers = await this.getTeamMembers()
    if (this.teamMembers.length) {
      this.loading = false
    }
  },
  methods: {
    initTeamMembersFilters() {
      this.teamMembersFilters = {
        "global": { value: null, matchMode: FilterMatchMode.CONTAINS },
        "first_name": {
          operator: FilterOperator.OR,
          constraints: [{ value: null, matchMode: FilterMatchMode.CONTAINS }]
        },
        "last_name": {
          operator: FilterOperator.OR,
          constraints: [{ value: null, matchMode: FilterMatchMode.CONTAINS }]
        },
        "email": {
          operator: FilterOperator.OR,
          constraints: [{ value: null, matchMode: FilterMatchMode.CONTAINS }]
        },
        "jira_name": {
          operator: FilterOperator.OR,
          constraints: [{ value: null, matchMode: FilterMatchMode.CONTAINS }]
        },
      }
    },
    async getTeamMembers() {
      ApiService.setHeader()
      let resp
      try {
        resp = await ApiService.get(`/team-members`)
      } catch (e) {
        console.error("Problem fetching team members", e)
      }
      if (resp) {
        return _.map(resp.data.data, ((tm) => {
          return { id: tm.id, ...tm.attributes }
        }))
      }

      return []
    },
    async userAction(e, id: Number) {
      const action = e.target.value
      if (action == "inactivate" || action == "activate") {
        await this.userStatus(action, id)
      } else if (action == "edit") {
        const modalId = String('id_' + id)
        const modalElement = document.getElementById(modalId)
        const modalInstance = Modal.getOrCreateInstance(modalElement)
        modalInstance.show()
      }
      e.target.value = "default"
    },

    async userStatus(action, id) {
      let status = action == "inactivate" ? "inactive" : "active"
      let choice = await Swal.fire({
        text: `Are you sure you want to make the team member ${status}?`,
        icon: "question",
        confirmButtonText: "Ok",
        showCancelButton: true,
        buttonsStyling: false,
        reverseButtons: true,
        customClass: {
          confirmButton: "btn btn-light-primary",
          cancelButton: "btn btn-danger me-3"
        }
      })
      // reload table with new or init data after modal close
      if (choice.isConfirmed) {
        ApiService.setHeader()
        let updateCustomResponse
        try {
          updateCustomResponse = await ApiService.put(`/team-members/${id}`, { data: { status: status } })
        } catch (e) {
          console.error("Problem setting user status", e)
        }
        if (updateCustomResponse.data) {
          await this.reloadTable()
        }
      }
    },

    statusText(status) {
      status = typeof status === "string" ? status.toLowerCase() : status
      switch (status) {
        case 0:
        case "inactive":
          return "Inactive"
        case 1:
        case "active":
          return "Active"
        case 21:
        case "archived":
          return "Archived"
        default:
          break
      }
    },
    async reloadTable() {
      this.loading = true
      this.teamMembers = []
      this.teamMembers = await this.getTeamMembers()
      this.loading = false
    },
    clearFilters() {
      this.initTeamMembersFilters()
    },
  },
  watch: {
    forceReload: {
      deep: true,
      handler(val, oldVal) {
        if (val != oldVal) {
          this.reloadTable()
        }
      }
    }
  }
});
