
import { defineComponent, ref } from "vue"
import * as Yup from "yup"
import { ErrorMessage, Field, Form } from "vee-validate"
import Swal from "sweetalert2/dist/sweetalert2.js"
import ApiService from "@/core/services/ApiService"
import { Modal } from "bootstrap"
import { TeamMember } from "@/core/interfaces/teamMember"
import Dropzone from "@/components/Dropzone.vue"
import axios from "axios"

export default defineComponent({
  name: "addTeamMember",
  components: {
    ErrorMessage,
    Field,
    Form,
    Dropzone
  },
  emits: ["reloadTable"],
  setup() {
    const addTeamMember = ref<TeamMember>({
      first_name: "",
      last_name: "",
      email: "",
      phone: "",
      status: "active",
      avatar_url: "",
      job_title: "",
      city: "",
      state: "",
      salesforce_id: "",
      teamwork_id: "",
      jira_name: "",
      max_time_for_tasks: null,
      bio: "",
      meeting_link: ""
    })

    const initAddTeamMember = () => {
      addTeamMember.value.first_name = ""
      addTeamMember.value.last_name = ""
      addTeamMember.value.email = ""
      addTeamMember.value.phone = ""
      addTeamMember.value.avatar_url = ""
      addTeamMember.value.job_title = ""
      addTeamMember.value.city = ""
      addTeamMember.value.state = ""
      addTeamMember.value.salesforce_id = ""
      addTeamMember.value.teamwork_id = ""
      addTeamMember.value.jira_name = ""
      addTeamMember.value.max_time_for_tasks = null
      addTeamMember.value.bio = ""
      addTeamMember.value.meeting_link = ""
    }

    const addTeamMemberFormValidator = Yup.object().shape({
      email: Yup.string().required().email().label("Email"),
      first_name: Yup.string().required().label("First Name"),
      last_name: Yup.string().required().label("Last Name"),
      phone: Yup.string().label("Phone Number"),
      job_title: Yup.string().label("Job Title"),
      city: Yup.string().label("City"),
      state: Yup.string().label("State"),
      salesforce_id: Yup.string().label("Salesforce ID"),
      teamwork_id: Yup.string().label("Teamwork ID"),
      jira_name: Yup.string().label("Jira Name"),
      max_time_for_tasks: Yup.string().label("Max Time For Tasks"),
      bio: Yup.string().label("Bio"),
      meeting_link: Yup.string().label("Meeting Link"),
    })

    const submitAddTeamMemberRequest = ref<HTMLElement | null>(null)
    let avatar = ""

    return {
      addTeamMemberFormValidator,
      submitAddTeamMemberRequest,
      initAddTeamMember,
      addTeamMember,
      avatar
    }
  },
  methods: {
    closeModal() {
      const modalElement = document.getElementById("addNewUser")
      const modalInstance = Modal.getInstance(modalElement)
      modalInstance.hide()

      // Hacky fix for bootstrap modal's backdrop persisting
      document.querySelector(".modal-backdrop")?.remove()
    },
    async sendRequest() {

      const ax = axios.create({
        baseURL: process.env.VUE_APP_FOOTPRINT_API_ENDPOINT || "https://footprint-api.hlmtech.com",
        headers: { "Content-Type": this.avatar["type"] }
      })

      ApiService.setHeader()

      let addTeamMemberResponse, addAvatarResponse
      try {
        addTeamMemberResponse = await ApiService.post("/team-members", { data: { ...this.addTeamMember } })
      } catch (e) {
        console.error("Problem fetching add user response", e)
      }

      if (addTeamMemberResponse.status == 201) {

        let teamMemberId = addTeamMemberResponse.data.data.id

        if (this.avatar != "") {
          addAvatarResponse = await ax.put(`/team-members/${ teamMemberId }/data/avatar`, this.avatar)
        }


        if (addAvatarResponse && addAvatarResponse.status == 200) {
          return { status: addTeamMemberResponse.status, message: "TeamMember created with avatar" }
        } else if (!addAvatarResponse) {
          return { status: addTeamMemberResponse.status, message: "TeamMember created without avatar" }
        } else {
          return { message: "TeamMember created, error adding avatar" }
        }

      } else {
        return { message: "Error creating TeamMember" }
      }
    },
    async saveUser() {
      if (this.submitAddTeamMemberRequest) {
        // Activate indicator
        this.submitAddTeamMemberRequest.setAttribute("data-kt-indicator", "on")

        const response = await this.sendRequest()
        let choice

        if (response.status == 201) {
          choice = await Swal.fire({
            text: response.message,
            icon: "success",
            confirmButtonText: "Ok",
            buttonsStyling: false,
            customClass: {
              confirmButton: "btn btn-light-primary"
            }
          })
        } else {
          choice = await Swal.fire({
            text: response.message,
            icon: "error",
            confirmButtonText: "Ok",
            buttonsStyling: false,
            customClass: {
              confirmButton: "btn btn-light-primary"
            }
          })
        }

        // cleanup / reload things
        if (choice) {
          this.submitAddTeamMemberRequest.removeAttribute("data-kt-indicator")
          this.initAddTeamMember()
          this.closeModal()
          this.$emit("reloadTable")
        }

      }
    },
    fileAdded(val) {
      if (val.upload.filename.match("^.*.(jpg|JPG|png|PNG)$")) {
        console.log("added: ", val.upload.filename)
      } else {
        Swal.fire({
          text: "Please upload a .jpg or .png file",
          icon: "error",
          confirmButtonText: "Ok",
          buttonsStyling: false,
          customClass: {
            confirmButton: "btn btn-light-primary"
          }
        })
        return
      }
      this.avatar = val
    },
    fileRemoved(val) {
      console.log("removed: ", val.upload.filename)
      this.avatar = ""
    },
  }
})
