
import { defineComponent, ref } from "vue"
import AddTeamMember from "@/components/modals/CreateTeamMemberModal.vue"
import { Modal } from "bootstrap"
import TeamMembersTable from "@/components/TeamMembersTable.vue"

export default defineComponent({
  name: "team-members",
  components: {
    TeamMembersTable,
    AddTeamMember
  },
  data() {
    let forceReload = ref(false).value

    return {
      key: 0,
      forceReload
    }
  },
  methods: {
    async reloadTable() {
      this.key++
      this.forceReload = !this.forceReload
    },
    openAddUserModal() {
      const modalElement = document.getElementById("addNewUser")
      const modalInstance = Modal.getOrCreateInstance(modalElement)
      modalInstance.show()
    }
  }
});
