
import { defineComponent, ref } from "vue"
import * as Yup from "yup"
import { ErrorMessage, Field, Form } from "vee-validate"
import Swal from "sweetalert2/dist/sweetalert2.js"
import ApiService from "@/core/services/ApiService"
import { Modal } from "bootstrap"
import { TeamMember } from "@/core/interfaces/teamMember"
import Dropzone from "@/components/Dropzone.vue"
import axios from "axios"

export default defineComponent({
  name: "Edit Team Member Modal",
  props: {
    user: {
      type: Object,
      required: true
    },
    modalId: String,
  },
  emits: ["reloadTable"],
  components: {
    ErrorMessage,
    Field,
    Form,
    Dropzone
  },
  setup(props) {
    const submitEditTeamMemberRequest = ref<HTMLElement | null>(null)

    const closeModal = (id) => {
      // close open editUserModal
      const modalId = String('id_' + id)
      const modalElement = document.getElementById(modalId)
      const modalInstance = Modal.getInstance(modalElement)
      modalInstance.hide()
    }

    let avatar = ""

    const editTeamMemberFormValidator = Yup.object().shape({
      email: Yup.string().required().email().label("Email"),
      first_name: Yup.string().required().label("First Name")
    })

    return {
      avatar,
      closeModal,
      submitEditTeamMemberRequest,
      editTeamMemberFormValidator,
      editTeamMember: ref(props.user).value as TeamMember
    }
  },
  methods: {
    deleteAvatar(id) {
      console.log('delete user ' + id + '\'s avatar');
      this.editTeamMember.avatar_url = "";
    },
    async sendRequest() {

      const ax = axios.create({
        baseURL: process.env.VUE_APP_FOOTPRINT_API_ENDPOINT || "https://footprint-api.hlmtech.com",
        headers: { "Content-Type": this.avatar["type"] }
      })

      ApiService.setHeader()

      let updateCustomResponse, addAvatarResponse
      try {
        updateCustomResponse = await ApiService.put(`/team-members/${this.editTeamMember.id}`, { data: this.editTeamMember })
      } catch (e) {
        console.error("Problem fetching update Team Member response", e)
        return { message: "error updating team member" }
      }

      if (updateCustomResponse.data) {
        if (this.avatar != "") {
          addAvatarResponse = await ax.put(`/team-members/${this.editTeamMember.id}/data/avatar`, this.avatar)
        }
        if (addAvatarResponse && addAvatarResponse.status == 200) {
          return { status: updateCustomResponse.status, message: "with avatar" }
        } else if (!addAvatarResponse) {
          return { status: updateCustomResponse.status, message: "without avatar" }
        } else {
          return { message: "error updating team member" }
        }
      }

      return { message: "error updating team member" }
    },
    async updateTeamMember() {
      if (this.submitEditTeamMemberRequest) {
        // Activate indicator
        this.submitEditTeamMemberRequest.setAttribute("data-kt-indicator", "on")

        let response = await this.sendRequest()
        let choice
        if (response.status == 200) {
          choice = await Swal.fire({
            text: `The team member was updated successfully ${response.message}!`,
            icon: "success",
            confirmButtonText: "Ok",
            buttonsStyling: false,
            customClass: {
              confirmButton: "btn btn-light-primary"
            }
          })
          this.submitEditTeamMemberRequest.removeAttribute("data-kt-indicator")
          this.closeModal(this.editTeamMember.id)
        } else {
          choice = await Swal.fire({
            text: response.message,
            icon: "error",
            confirmButtonText: "Ok",
            buttonsStyling: false,
            customClass: {
              confirmButton: "btn btn-light-primary"
            }
          })
          this.submitEditTeamMemberRequest.removeAttribute("data-kt-indicator")
          this.closeModal(this.editTeamMember.id)
        }

        // reload table with new or init data after modal close
        if (choice) {
          this.$emit("reloadTable")
        }

      }
    },
    fileAdded(val) {
      if (val.upload.filename.match("^.*.(jpg|JPG|png|PNG)$")) {
        console.log("added: ", val.upload.filename)
      } else {
        Swal.fire({
          text: "Please upload a .jpg or .png file",
          icon: "error",
          confirmButtonText: "Ok",
          buttonsStyling: false,
          customClass: {
            confirmButton: "btn btn-light-primary"
          }
        })
        return
      }
      this.avatar = val
    },
    fileRemoved(val) {
      console.log("removed: ", val.upload.filename)
      this.avatar = ""
    },
  }
});
